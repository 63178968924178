<style type="text/css">
  @import "~@/assets/css/common.css";
</style>
<!-- 物流公司 -->
<style scoped>
  .check-distri label {
    margin-bottom: 0;
  }

  /deep/.el-input__inner {
    height: 30px;
    line-height: 30px;
    border-color: rgb(23, 118, 210);
  }

  /deep/.el-select .el-input__inner {
    border-color: rgb(23, 118, 210);
    height: 30px
  }

  /deep/ .el-form-item__content {
    line-height: inherit;
  }
</style>
<script>
  import Layout from "@/views/layouts/main";
  import CheckHeader from "@/components/check-header";
  import {
    getWlgs,
    editWlgs,
    deleteWlgs,
    addWlgs
  } from "@/api/zhengshu/manage.js"

  export default {
    components: {
      Layout,
      CheckHeader,
    },
    data() {
      return {
        title: "物流公司管理",
        items: [{
            text: "用户首页",
            href: "/admin"
          },
          {
            text: "物流公司",
            active: true
          }
        ],
        showmodal: false,
        addForm: {
          wlgs: '',
          sfbz: '',
          qyzt: '',
          sid: ''
        },
        dialogTitle: "添加物流公司",
        tableList: [],
        pageData: {
          pageNum: 1,
          pageSize: 20,
          total: 0,
          keyword: ""
        },
        years: [],
        zskid: ''
      };
    },
    methods: {
      // 证书记录分页
      getList() {
        getWlgs(this.pageData).then(res => {
          if (res.status) {
            this.tableList = res.data
            this.pageData.total = res.total
          }
        })
      },
      searchClick() {
        this.pageData.pageNum = 1
        this.getList()
      },
      // 删除
      deleteItem(sid, text) {
        this.$confirm(`此操作将永久删除该【${text}】的数据, 是否继续?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          deleteWlgs(sid).then(res => {
            if (res.status) {
              this.$message({
                type: 'success',
                message: '数据删除成功!'
              });
            }
            this.getList()
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
        });
      },
      handleSizeChange(val) {
        this.pageData.pageSize = val
        this.getList();
      },
      handleCurrentChange(val) {
        this.pageData.pageNum = val
        this.getList();
      },
      addItem() {
        this.addForm = {}
        this.addForm.nf = new Date().getFullYear();
        this.showmodal = true;
        this.dialogTitle = "添加物流公司";
      },
      // 打开编辑
      editItem(data) {
        this.addForm = data;
        if (this.addForm.sfbz) {
          this.addForm.sfbz = this.addForm.sfbz / 100
        }
        this.dialogTitle = "修改物流公司";
        this.showmodal = true;
      },
      // 提交判断
      submit() {
        if (this.addForm.sfbz) {
          this.addForm.sfbz = 100 * this.addForm.sfbz
        }
        if (this.addForm.sid) {
          this.editCertKu(this.addForm)
        } else {
          this.addCertKu(this.addForm)
        }
      },
      // 添加
      addCertKu(obj) {
        let formDate = JSON.parse(JSON.stringify(obj))
        addWlgs(formDate).then(res => {
          if (res.status) {
            this.$message({
              type: 'success',
              message: res.message,
            })
            this.showmodal = false
            if (this.zskid) {
              this.$router.push({
                path: '/admin/cerImportLogistics',
                query: {
                  zskid: this.zskid
                }
              })
            } else {
              this.getList()
            }
          }

        })
      },
      // 修改
      editCertKu(val) {
        let formDate = JSON.parse(JSON.stringify(val))
        editWlgs(formDate).then(res => {
          if (res.status) {
            this.$message({
              type: 'success',
              message: res.message,
            })
            this.showmodal = false
            if (this.zskid) {
              this.$router.push({
                path: '/admin/cerImportLogistics',
                query: {
                  zskid: this.zskid
                }
              })
            } else {
              this.getList()
            }
          }
        })
      },
      //获取当前年份开始的前后几年
      getYear() { //获取年份，当前年前后几年
        var y = new Date().getFullYear();
        for (var i = 0; i <= 5; i++) {
          if (i < 5) {
            this.years.unshift({
              value: (y - i),
              label: (y - i)
            })
          } else {
            for (var x = 1; x < 3; x++) {
              this.years.push({
                value: (y + x),
                label: (y + x)
              })
            }
          }
        }
        this.years = this.years.reverse();
      },
    },
    mounted() {
      this.getList()
      this.getYear();
    },
    created() {
      this.zskid = this.$route.query["zskid"] ? this.$route.query["zskid"] : "";
    }
  };
</script>

<template>
  <Layout>
    <CheckHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card" style="min-height: 600px;">
          <div class="card-body">
            <div class="pb-3 border-dash check-table-top">
              <div class="flexList" style="flex: 1;">
                <input placeholder="关键字" class="h30 form-control border-blue mr-2" maxlength="50" style="width: 10%;"
                  v-model="pageData.keyword" />
                <button type="button" class="btn btn-info h30 flexList mr-2 w-sm " @click="searchClick"><i
                    class="iconfont mr-2 icon-mb-search"></i>查询</button>
                <b-button variant="outline-info" class="flexList  condition" @click="addItem()"><i
                    class="iconfont icon-plus-circle1 mr-2 font-size-20"></i>添加物流公司</b-button>
              </div>
              <!--              <div class="d-flex">-->
              <!--                <div class="border-blue export-tab"-->
              <!--                  @click="$exportModal().show({title:exportTitle, type: 'xlsx', module:module,condition:pageData})"><i-->
              <!--                    class="iconfont icon-antOutline-file-excel mr-2"></i>excel</div>-->
              <!--                <div class="border-blue export-tab"-->
              <!--                  @click="$exportModal().show({title: exportTitle, type:'dbf', module:module,condition:pageData})"><i-->
              <!--                    class="iconfont icon-data mr-2"></i>dbf</div>-->
              <!--              </div>-->
            </div>
            <div class="table-responsive border mt-3">
              <table class="table light-table table-hover table-bordered ">
                <thead class="thead-light">
                  <tr>
                    <th style="width: 5%;">序号</th>
                    <th>物流公司</th>
                    <th style="width: 10%;">收费标准</th>
                    <th style="width: 10%;">自动编号</th>
                    <th style="width: 10%;">年份</th>
                    <th style="width: 10%;">启用状态</th>
                    <th style="width: 10%;">创建时间</th>
                    <th style="width: 10%;">操作</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(obj,index) in tableList" :key="index">
                    <td>{{index+1+(pageData.pageNum-1)*pageData.pageSize}}</td>
                    <td>{{obj.wlgs}}</td>
                    <td>{{Number(obj.sfbz)/100}}元 </td>
                    <td>{{obj.qym}}</td>
                    <td>{{obj.nf}}</td>
                    <td>
                      <b-form-checkbox v-model="obj.qyzt" switch class="mt-1 switch-check" :value="true"
                        :unchecked-value="false" @change="editCertKu({'sid':obj.sid,'qyzt':obj.qyzt})">
                      </b-form-checkbox>
                    </td>
                    <td>{{obj.createTime}}</td>
                    <td class="tab-icon ">
                      <i class="iconfont icon-edit-two mr-1" @click="editItem(obj)"></i>
                      <i class="iconfont icon-riLine-delete-bin-line" @click="deleteItem(obj.sid,obj.wlgs)"></i>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="float-right d-flex">
              <el-pagination @size-change="handleSizeChange" background @current-change="handleCurrentChange"
                :current-page.sync="pageData.pageNum" :page-size="pageData.pageSize"
                layout="total, sizes,jumper, prev, pager, next" :total="pageData.total">
              </el-pagination>
            </div>
          </div>
        </div>
      </div>


    </div>
    <!-- 弹窗开始 -->

    <!-- 添加证书库 -->
    <!--    <AddCertKu @submit="submit" :title="dialogTitle" ref="AddCertKu" v-model="addForm"></AddCertKu>-->
    <b-modal id="addZhengshu" v-model="showmodal" :title="this.dialogTitle" centered title-class="font-18" hide-footer>
      <div>
        <el-form ref="addForm" :model="addForm">
          <el-form-item>
            <div class="d-flex check-distri mb-3"><label>物流年份：</label>
              <div class=" col-sm-10 p-0 flexList">
                <el-select class=" mr-2 " placeholder="年份" v-model="addForm.nf" size="small">
                  <el-option v-for="(item, i) in years" :label="item.label" :value="item.value" :key="i">
                  </el-option>
                </el-select>
              </div>
            </div>
          </el-form-item>
          <el-form-item>
            <div class="d-flex check-distri mb-3"><label>物流公司：</label>
              <div class=" col-sm-10 p-0 flexList">
                <input type="text" placeholder="请输入物流公司名称" v-model="addForm.wlgs" class="form-control w-80 h30 " />
              </div>
            </div>
          </el-form-item>
          <el-form-item>
            <div class="d-flex check-distri mb-3"><label>收费标准：</label>
              <div class="col-sm-10  p-0">
                <input type="text" placeholder="请输入收费标准（元）" v-model="addForm.sfbz" class="form-control w-80 h30 " />
              </div>
            </div>
          </el-form-item>
          <el-form-item>
            <div class="d-flex check-distri mb-3"><label>启用状态：</label>
              <div class="col-sm-10 p-0 flexList">
                <b-form-checkbox v-model="addForm.qyzt" switch class="mt-1 switch-check" :value="true"
                  :unchecked-value="false">
                </b-form-checkbox>
              </div>
            </div>
          </el-form-item>
        </el-form>
      </div>
      <div class=" mt-3  text-center"><button type="button" class="btn btn-info h30 w-md mr-3"
          @click="submit">确定</button>
        <button type="button" class="btn btn-secondary h30  w-md" @click="showmodal = false">取消</button>
      </div>


    </b-modal>
    <!-- 弹窗结束 -->
  </Layout>
</template>
